@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter' , sans-serif;
}

:root{
  --primary-color: '#3b82f6';
  --secondary-color: '#eff6ff';
  --searchBar-color: '#dbeafe';
  --searchBar-outline: '#93c5fd';

}

::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}

::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
}

